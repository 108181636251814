.article-celebrity table, .article-zpravy table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: none;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: normal;
  overflow-x: scroll;
  width: auto !important;
  height: auto !important;
}

.article-celebrity tbody, .article-zpravy tbody {
  width: 100%;
  max-width: 540px;
  display: block;
  overflow-x: auto;
}

.article-celebrity tr:first-child td, .article-zpravy tr:first-child td {
  color: #fff;
  vertical-align: middle;
  background: #16212d;
  border: 0;
  font-weight: bold;
  height: 100% !important;
}

.article-celebrity tr:first-child td:before, .article-celebrity tr:first-child td:after, .article-zpravy tr:first-child td:before, .article-zpravy tr:first-child td:after {
  display: none;
}

.article-celebrity td, .article-zpravy td {
  vertical-align: middle;
  background: #fff;
  padding: 5px 0 5px 30px;
  position: relative;
  height: 100% !important;
}

.article-celebrity td:before, .article-zpravy td:before {
  content: " ";
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.article-celebrity td:first-child, .article-zpravy td:first-child {
  z-index: 1;
  padding-right: 30px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.article-celebrity td:first-child:before, .article-zpravy td:first-child:before {
  width: calc(100% - 20px);
}

.article-celebrity td:first-child:after, .article-zpravy td:first-child:after {
  content: " ";
  background: #dcdcdc;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.article-celebrity td:last-child, .article-zpravy td:last-child {
  padding-right: 30px;
}

.article-celebrity td:last-child:before, .article-zpravy td:last-child:before {
  width: calc(100% - 20px);
  left: 0;
  right: auto;
}

.article-celebrity td strong, .article-zpravy td strong {
  font-weight: normal;
}

.article-zpravy tbody {
  max-width: 690px;
}

.article-celebrity table, .article-zpravy table {
  width: 100% !important;
}

.article-celebrity tbody, .article-zpravy tbody {
  width: 100%;
  max-width: 100%;
}

.article-celebrity tr, .article-zpravy tr {
  border: none !important;
}

.subscription--new {
  clear: both;
  background: #e9eff4;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 7px 0;
}

.subscription--new.subscription--690 {
  width: 690px;
  margin: 0 0 20px -50px !important;
}

.subscription--new .welcome {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: -3px;
  font-size: 31px;
  font-weight: bold;
  line-height: 37px;
  display: block;
}

.subscription--new .subscription__toggle {
  text-align: center;
  margin: 20px 0 50px;
}

.subscription--new .subscription__toggle-button {
  color: red;
  background: none;
  border: 0;
  margin: 5px 0 0;
  font-size: 14px;
  text-decoration: underline;
}

.subscription--new .subscription__toggle-button:hover {
  cursor: pointer;
  text-decoration: none;
}

.subscription--new .subscription__toggle-button:active, .subscription--new .subscription__toggle-button:focus {
  outline: none;
}

.subscription--new .subscription__headline {
  text-align: center;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 40px;
}

.subscription--new .subscription__sub-headline {
  margin: 30px auto 20px;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

.subscription--new .subscription__logo {
  background-size: 190px;
  width: 190px;
  height: 115px;
  margin: 0 auto 22px;
}

.subscription--new .subscription__login {
  text-align: center;
  margin: 10px 0 40px;
  font-size: 14px;
  line-height: 28px;
}

.subscription--new .subscription__login-link {
  font-weight: normal;
  text-decoration: underline;
}

.subscription--new .subscription__login-link:hover {
  text-decoration: none;
}

.subscription--new .subscription__login-link.no-icon:after {
  content: "";
  display: none;
}

.subscription--new .subscription__type-voucher {
  text-align: center;
  color: #1e1e1e;
  box-sizing: content-box;
  background: #f8f8f8;
  width: calc(100% - 30px);
  min-height: 123px;
  margin: 0 0 10px;
  padding: 15px;
  font-size: 13px;
  line-height: 22px;
  list-style-type: none;
  position: relative;
  overflow: hidden;
}

.subscription--new .subscription__type-voucher:before {
  display: none;
}

.subscription--new .subscription__type-voucher .subscription__type-title {
  text-align: right;
  float: right;
  width: calc(100% - 40px);
  margin: 0 20px 15px 0;
}

.subscription--new .subscription__type-voucher .subscription__type-price {
  text-align: right;
  width: calc(100% - 80px);
  margin-right: 80px;
}

.subscription--new .subscription__type-voucher .subscription__type-order {
  float: right;
  width: 170px;
  margin-top: 10px;
  margin-right: 60px;
}

.subscription--new .subscription__type-voucher .subscription__coupon-voucher {
  width: 50%;
  max-width: 310px;
  margin: 0;
  position: absolute;
  bottom: 7px;
  left: 20px;
  transform: rotate(-10deg);
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, .25);
}

.subscription--new .subscription__help {
  color: #2c2f3b;
  content: "?";
  text-align: center;
  background: #d1d4e4;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  position: relative;
}

.subscription--new .subscription__help:hover {
  cursor: help;
}

.subscription--new .subscription__help:hover .subscription__help-text {
  text-align: left;
  background: #fcf7db;
  width: 250px;
  padding: 15px;
  font-size: 13px;
  font-weight: 500;
  display: block;
  position: absolute;
  bottom: 35px;
  left: -133px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.subscription--new .subscription__help:hover .subscription__help-text p:first-child {
  margin-bottom: 15px;
}

.subscription--new .subscription__help:hover .subscription__help-text:after {
  content: "";
  filter: drop-shadow(0 5px 3px rgba(0, 0, 0, .16));
  border: 10px solid rgba(0, 0, 0, 0);
  border-top-color: #fcf7db;
  border-bottom-width: 5px;
  width: 0;
  height: 0;
  margin-left: -5px;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 50%;
}

.subscription--new .subscription__help-text {
  display: none;
}

.subscription--new .subscription__buttons {
  text-align: center;
  margin: 35px 0 40px;
}

.subscription--new .subscription__button {
  color: #403014;
  background: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 2px;
  height: 28px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
}

.subscription--new .subscription__button.active {
  color: #d21e1e;
  border: 1px solid #d21e1e;
}

.subscription--new .subscription__button:first-child {
  margin-right: 7px;
}

.subscription--new .subscription__button:focus {
  outline: 0;
}

.subscription--new .subscription__types {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 10px;
  display: flex;
}

.subscription--new .subscription__type {
  box-sizing: border-box;
  float: left;
  text-align: center;
  color: #1e1e1e;
  background: #fff;
  flex: 0 0 48%;
  width: 270px;
  margin: 0;
  padding: 15px;
  font-size: 13px;
  line-height: 22px;
  list-style-type: none;
  position: relative;
}

.subscription--new .subscription__type p.subscription__type-note {
  color: #1e1e1e;
  min-height: 41px;
  margin: 15px 0;
  font-size: 13px;
  line-height: 20px;
}

.subscription--new .subscription__type:first-child {
  margin-right: 10px;
}

.subscription--new .subscription__type:last-child, .subscription--new .subscription__type:nth-child(2n) {
  margin-left: 10px;
}

.subscription--new .subscription__type:last-child p.subscription__type-note, .subscription--new .subscription__type:nth-child(2n) p.subscription__type-note {
  margin-top: 9px;
  margin-bottom: 18px;
  line-height: 26px;
}

.subscription--new .subscription__type.id-2 {
  min-width: 93%;
  margin: 0 auto 30px;
}

.subscription--new .subscription__type:last-child {
  margin-bottom: 0;
}

.subscription--new .subscription__type-order {
  color: #fff;
  background: red;
  border-radius: 2px;
  width: 150px;
  height: 40px;
  margin: 30px auto 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 45px;
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
}

.subscription--new .subscription__type-order--white {
  color: red;
  background: #fff;
  border: 1px solid red;
  font-weight: 400;
}

.subscription--new .subscription__type-order--white:hover {
  color: #fff;
}

.subscription--new .subscription__type-order:focus {
  outline: 0;
}

.subscription--new .subscription__type-order:hover {
  background: #a81818;
  text-decoration: none;
}

.subscription--new .subscription__coupon-voucher {
  bottom: 40px;
}

.subscription--new .subscription__type-price {
  color: #1e1e1e;
  text-align: center;
  border: none;
  margin: 20px 0 2px;
  padding: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 40px;
  display: block;
}

.subscription--new .subscription__type-price-month {
  color: #1e1e1e;
  margin: 0 0 0 6px;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.subscription--new .subscription__type-perks {
  text-align: left;
  margin: 12px 0 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.subscription--new .subscription__type-perk {
  color: #403014;
  margin: 0 0 13px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  display: block;
  position: relative;
}

.subscription--new .subscription__type-perk:before {
  content: " ";
  background: url("/images/isportcz/dist/svg_fallback/advantage.png") 0 11px no-repeat;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.subscription--new .subscription__type-perk--negative:before {
  content: "❌";
  background: none;
  bottom: -6px;
  left: -2px;
}

.subscription--new .subscription__type-perk:last-child {
  margin: 0;
}

.subscription--new .subscription__type-title {
  margin: 0 0 12px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;
}

.subscription--new .subscription__type-subtitle {
  text-align: center;
  color: #1e1e1e;
  margin: -3px 0 22px;
  font-family: arial, sans-serif;
  font-size: 22px;
  line-height: 28px;
  display: block;
}

.subscription--new .subscription__corporate-title, .subscription--new .subscription__corporate-note, .subscription--new .subscription__advantages-bullet {
  font-size: 14px;
}

.subscription--new .subscription__corporate {
  border: 0;
}

.subscription--new .subscription__payment-options {
  justify-content: space-between;
  width: 93%;
  margin: 30px auto 50px;
  font-size: 12px;
  display: flex;
}

.subscription--new .subscription__payment-option {
  float: left;
  align-items: center;
  margin: 0 0 0 10px;
  padding: 0 5px 0 0;
  display: flex;
}

.subscription--new .subscription__payment-option:last-child {
  padding-right: 0;
}

.subscription--new .subscription__payment-option-desc {
  display: inline-block;
}

.subscription--new .subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

.subscription--new .subscription__payment-options-headline {
  float: left;
  margin: 4px 0 0;
  font-family: arial, sans-serif;
  line-height: 15px;
  font-size: 14px !important;
}

.subscription--new .subscription__advantages-headline {
  margin: 0 0 30px;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.subscription--new .subscription__advantages-headline-extra {
  margin: 20px 0 30px;
  font-size: 14px;
}

.subscription--new .subscription__advantages-bullet {
  box-sizing: border-box;
  background: url("/images/blesk/paid-content/check-icon.png?v=1") 0 5px / 14px no-repeat;
  width: 50%;
  margin: 0 0 10px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
}

.subscription--new .subscription__advantages-bullets {
  text-align: left;
  flex-wrap: wrap;
  margin: 0;
  display: flex;
}

.subscription--new .subscription__advantages {
  border: 0;
  width: 100%;
  margin: 50px 0 0;
  padding: 0;
  display: inline-block;
}

.subscription--new .subscription__advantages-footnote {
  text-align: center;
  width: 50%;
  margin: 35px auto 40px;
  font-size: 14px;
  line-height: 18px;
}

.subscription--new .subscription__footnote-link {
  color: red;
  text-decoration: underline;
}

.subscription--new .subscription__footnote-link:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-minimal {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 14px;
  display: flex;
}

.subscription--new .subscription__type-minimal:last-child {
  margin-bottom: 0;
}

.subscription--new .subscription__type-minimal-link {
  color: red;
  text-decoration: underline;
}

.subscription--new .subscription__type-minimal-link:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-extra {
  box-sizing: border-box;
  text-align: center;
  background: #e7c257;
  border: 1px solid #d9af2b;
  border-radius: 50%;
  width: 71px;
  height: 71px;
  padding: 3px;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  top: -25px;
  right: -10px;
}

.subscription--new .subscription__type-extra strong {
  color: #fff;
  margin-top: 2px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 25px;
  line-height: 28px;
  display: block;
}

.subscription--new div.subscription__zpravy .subscription__logo {
  background-size: contain !important;
  width: 116px !important;
  height: 67px !important;
}

.faq.premium-page .subscription-faq {
  padding: 20px;
}

.faq.premium-page .subscription-faq a {
  text-decoration: underline;
}

.faq.premium-page .subscription-faq a:hover {
  text-decoration: none;
}

.faq.premium-page .subscription-faq__headline {
  margin: 0 0 24px;
  font-size: 18px;
  line-height: 22px;
}

.faq.premium-page .subscription-faq__question, .faq.premium-page .subscription-faq__answer {
  font-size: 14px;
  line-height: 18px;
}

.faq.premium-page .subscription-faq__contact {
  margin: 20px 0 5px;
  font-size: 14px;
  line-height: 22px;
}

div.subscription-centered {
  line-height: 25px;
}

div.subscription-centered #subscription #subscription-offer {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

div.subscription-centered #subscription #subscription-offer li.subscription__advantages-bullet {
  min-height: initial;
}

#paid-zone.subscription__voucher .main-info {
  display: none;
}

#paid-zone.subscription__voucher .order__success-title {
  color: red;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

.articlePaid #subscription #subscription-offer .subscription__logo, .article-body #subscription #subscription-offer .subscription__logo, .articleText.subscription-bpz #subscription #subscription-offer .subscription__logo {
  background-size: contain;
  width: 116px;
  height: 67px;
}

#paid-zone .subscription--new + .subscription__faq, .subscription--new + .subscription__faq {
  width: 90%;
  margin: 20px auto 35px;
}

#paid-zone .subscription--new .welcome, .subscription--new .welcome {
  font-size: 22px;
  line-height: 29px;
}

#paid-zone .subscription--new .subscription__headline, .subscription--new .subscription__headline {
  margin: 20px auto 15px;
  font-size: 28px;
  line-height: 33px;
}

#paid-zone .subscription--new .subscription__help, .subscription--new .subscription__help {
  margin-left: 2px;
}

#paid-zone .subscription--new .subscription__logo, .subscription--new .subscription__logo {
  background-size: 150px;
  width: 150px;
  margin: 0 auto -30px;
}

#paid-zone .subscription--new .subscription__types, .subscription--new .subscription__types {
  flex-direction: column;
  align-items: center;
}

#paid-zone .subscription--new .subscription__type, .subscription--new .subscription__type {
  width: 90%;
  margin-bottom: 35px;
  padding: 30px 0;
}

#paid-zone .subscription--new .subscription__type.id-2, .subscription--new .subscription__type.id-2 {
  min-width: 90%;
}

#paid-zone .subscription--new .subscription__type-order, .subscription--new .subscription__type-order {
  width: 155px;
  line-height: 40px;
}

#paid-zone .subscription--new .subscription__type-perks, .subscription--new .subscription__type-perks {
  margin: 10px 0 0;
}

#paid-zone .subscription--new .subscription__payment-options, .subscription--new .subscription__payment-options {
  width: 90%;
  margin: 33px auto 0;
}

#paid-zone .subscription--new .subscription__payment-options-bullets, .subscription--new .subscription__payment-options-bullets {
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

#paid-zone .subscription--new .subscription__advantages, .subscription--new .subscription__advantages {
  width: 90%;
  margin: 40px auto 0;
  display: block;
}

#paid-zone .subscription--new .subscription__advantages-bullets, .subscription--new .subscription__advantages-bullets {
  flex-direction: column;
  padding: 0;
}

#paid-zone .subscription--new .subscription__advantages-headline, #paid-zone .subscription--new .subscription__advantages-headline-extra, .subscription--new .subscription__advantages-headline, .subscription--new .subscription__advantages-headline-extra {
  font-weight: bold;
}

#paid-zone .subscription--new .subscription__advantages-headline, .subscription--new .subscription__advantages-headline {
  font-size: 18px;
  line-height: 21px;
}

#paid-zone .subscription--new .subscription__advantages-headline-extra, .subscription--new .subscription__advantages-headline-extra {
  margin: 30px 0;
  line-height: 20px;
}

#paid-zone .subscription--new .subscription__advantages-bullet, .subscription--new .subscription__advantages-bullet {
  width: 100%;
}

#paid-zone .subscription--new .subscription__advantages-bullet:last-child, .subscription--new .subscription__advantages-bullet:last-child {
  margin: 0;
}

#paid-zone .subscription--new .subscription__payment-options-headline, .subscription--new .subscription__payment-options-headline {
  margin: 0;
  font-weight: bold;
}

#paid-zone .subscription--new .subscription__payment-option, .subscription--new .subscription__payment-option {
  background: none;
  margin: 0;
  padding: 0;
}

#paid-zone .subscription--new p.subscription__type-note, .subscription--new p.subscription__type-note {
  font-weight: initial;
  color: #1e1e1e;
  margin: 5px 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription--new .subscription__price-note, .subscription--new .subscription__price-note {
  color: #98a3af;
  font-size: 13px;
  line-height: 20px;
}

#paid-zone .subscription--new .subscription__type-voucher, .subscription--new .subscription__type-voucher {
  text-align: center;
  color: #1e1e1e;
  box-sizing: border-box;
  background: #f8f8f8;
  border-bottom: none;
  flex-direction: column;
  width: 90%;
  min-height: 123px;
  margin: 0 auto 30px;
  padding: 15px;
  font-size: 13px;
  line-height: 22px;
  list-style-type: none;
  display: flex;
  position: relative;
}

#paid-zone .subscription--new .subscription__type-voucher .subscription__type-title, .subscription--new .subscription__type-voucher .subscription__type-title {
  text-align: center;
  width: 100%;
  margin: 15px auto;
}

#paid-zone .subscription--new .subscription__type-voucher .subscription__type-price, .subscription--new .subscription__type-voucher .subscription__type-price {
  text-align: center;
  margin: 0 auto 20px;
}

#paid-zone .subscription--new .subscription__type-voucher .subscription__type-note, .subscription--new .subscription__type-voucher .subscription__type-note {
  flex-flow: column-reverse;
  display: flex;
}

#paid-zone .subscription--new .subscription__type-voucher .subscription__type-order, .subscription--new .subscription__type-voucher .subscription__type-order {
  margin: 0 auto 15px;
}

#paid-zone .subscription--new .subscription__type-voucher .subscription__coupon-voucher, .subscription--new .subscription__type-voucher .subscription__coupon-voucher {
  width: 100%;
  margin: 0 auto 20px;
  position: static;
  transform: none;
  box-shadow: 0 13px 16px #000029;
}

#paid-zone .subscription--new .subscription__corporate, .subscription--new .subscription__corporate {
  width: 91%;
  margin: 20px auto;
}

#paid-zone .subscription--new .subscription__corporate-note, .subscription--new .subscription__corporate-note {
  float: right;
  width: 52%;
  display: inline-block;
}

#paid-zone .subscription--new .subscription__advantages-footnote, .subscription--new .subscription__advantages-footnote {
  width: auto;
  margin: 35px auto;
}

#paid-zone .subscription--new .subscription__type-price, .subscription--new .subscription__type-price {
  font-size: 40px;
  line-height: 40px;
}

#paid-zone .subscription--new .subscription__sub-headline, .subscription--new .subscription__sub-headline {
  width: 90%;
  margin-top: 40px;
  font-weight: bold;
}

#paid-zone .subscription--new .subscription__type-minimal, .subscription--new .subscription__type-minimal {
  box-sizing: border-box;
  align-items: baseline;
  width: 90%;
  margin: 0 auto;
  line-height: 21px;
}

#paid-zone .subscription--new .subscription__type-minimal-link, .subscription--new .subscription__type-minimal-link {
  margin-left: 40px;
}

#paid-zone .subscription--new .subscription__type-extra strong, .subscription--new .subscription__type-extra strong {
  margin-top: 1px;
}

.subscription-centered {
  line-height: 25px;
}

.subscription-centered #subscription #subscription-offer {
  margin: 0 auto;
}

.subscription__horoskopy #subscription #subscription-offer .subscription__logo, .main-article #subscription #subscription-offer .subscription__logo {
  width: 116px;
  height: 67px;
}

#paid-zone #subscription #subscription-offer ul.subscription__types li.subscription__type {
  width: initial;
  margin-bottom: 40px;
}

#paid-zone #subscription #subscription-offer ul.subscription__types li.subscription__type:last-child {
  margin-top: 40px;
}

body article.article_main .subscription .subscription__advantages ul.subscription__advantages-bullets li.subscription__advantages-bullet {
  margin-left: -20px;
}

body article.article_main .subscription .subscription__advantages ul.subscription__advantages-bullets li.subscription__advantages-bullet:first-child {
  margin-top: 0;
}

body article.main-article #subscription div.subscription, body div.subscription-bpz #subscription div.subscription {
  font-family: Open Sans, arial, sans-serif;
}

body article.main-article #subscription div.subscription .subscription__advantages-wrapper, body div.subscription-bpz #subscription div.subscription .subscription__advantages-wrapper {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  padding: 26px 0;
}

body article.main-article #subscription div.subscription ul.subscription__advantages-bullets, body div.subscription-bpz #subscription div.subscription ul.subscription__advantages-bullets {
  padding-left: 0;
}

body article.main-article #subscription div.subscription li.subscription__advantages-bullet, body div.subscription-bpz #subscription div.subscription li.subscription__advantages-bullet {
  font-size: 13px;
}

@media only screen and (max-width: 426px) {
  body article.main-article #subscription div.subscription, body div.subscription-bpz #subscription div.subscription {
    width: 405px;
    margin-left: -2px;
  }

  body article.main-article #subscription div.subscription h2.subscription__headline, body article.main-article #subscription div.subscription h3.subscription__advantages-headline, body article.main-article #subscription div.subscription h3.subscription__payment-options-headline, body article.main-article #subscription div.subscription div.subscription__payment-tooltiptext, body div.subscription-bpz #subscription div.subscription h2.subscription__headline, body div.subscription-bpz #subscription div.subscription h3.subscription__advantages-headline, body div.subscription-bpz #subscription div.subscription h3.subscription__payment-options-headline, body div.subscription-bpz #subscription div.subscription div.subscription__payment-tooltiptext {
    font-family: Open Sans, arial, sans-serif;
  }
}

@media only screen and (max-width: 376px) {
  body article.main-article #subscription div.subscription, body div.subscription-bpz #subscription div.subscription {
    width: 354px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 321px) {
  body article.main-article #subscription div.subscription, body div.subscription-bpz #subscription div.subscription {
    width: 300px;
    margin-left: 2px;
  }
}

body article.main-article #subscription div.subscription ul.subscription__types li.subscription__type, body div.subscription-bpz #subscription div.subscription ul.subscription__types li.subscription__type {
  background-color: #f8f8f8;
}

body article.main-article #subscription div.subscription ul.subscription__types li.subscription__type-voucher, body div.subscription-bpz #subscription div.subscription ul.subscription__types li.subscription__type-voucher {
  box-sizing: content-box;
  background-color: #f8f8f8;
}

body article.main-article #subscription div.subscription ul.subscription__types li.subscription__type-voucher:before, body div.subscription-bpz #subscription div.subscription ul.subscription__types li.subscription__type-voucher:before {
  display: none;
}

body article.main-article #subscription div.subscription .subscription__payment-tooltip .subscription__payment-tooltiptext, body div.subscription-bpz #subscription div.subscription .subscription__payment-tooltip .subscription__payment-tooltiptext {
  width: 238px;
}

body article.main-article #subscription div.subscription.fade-out:before, body div.subscription-bpz #subscription div.subscription.fade-out:before {
  background-image: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 106%;
}

body div.main article.detail .subscription__horoskopy {
  font-family: Roboto Regular, roboto, sans-serif;
}

body div.main article.detail .subscription__horoskopy div.subscription {
  margin-top: 20px;
  font-family: Roboto Regular, roboto, sans-serif;
}

@media only screen and (max-width: 426px) {
  body div.main article.detail .subscription__horoskopy div.subscription {
    width: 370px;
    margin-left: -13px;
    font-family: Roboto Regular, roboto, sans-serif;
  }

  body div.main article.detail .subscription__horoskopy div.subscription h2.subscription__headline, body div.main article.detail .subscription__horoskopy div.subscription .subscription__advantages h3.subscription__advantages-headline, body div.main article.detail .subscription__horoskopy div.subscription h3.subscription__payment-options-headline, body div.main article.detail .subscription__horoskopy div.subscription .subscription__payment-tooltip div.subscription__payment-tooltiptext {
    font-family: Roboto Regular, roboto, sans-serif;
  }
}

@media only screen and (max-width: 376px) {
  body div.main article.detail .subscription__horoskopy div.subscription {
    width: 355px;
    margin-left: -30px;
  }
}

@media only screen and (max-width: 321px) {
  body div.main article.detail .subscription__horoskopy div.subscription {
    width: 291px;
    margin-left: -26px;
  }
}

body div.main article.detail .subscription__horoskopy div.subscription ul.subscription__types li.subscription__type {
  background-color: #f8f8f8;
}

body div.main article.detail .subscription__horoskopy div.subscription.fade-out:before {
  background-image: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

body div.main article.detail .subscription__horoskopy div.subscription .subscription__advantages-wrapper {
  border-top: 1px solid #cad5df;
  border-bottom: 1px solid #cad5df;
  padding: 26px 0;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher {
  margin-top: 0;
}

#paid-zone #subscription #subscription-offer .subscription__types .subscription__type-voucher .subscription__type-order {
  width: 100%;
}

div.gallery-main-container div.gallery.non-paid-user div.gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor {
  margin-top: 100px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock {
  float: left;
  text-align: center;
  color: #fff;
  z-index: 2;
  background: #222b38;
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  top: 0;
  left: 0;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__logo {
  margin-top: 43px;
  margin-bottom: 18px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__logo svg {
  width: 131px;
  height: 76px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__logos {
  justify-content: center;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 20px;
  display: flex;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__logos img {
  height: 16px;
  max-height: 16px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__logo-pplus {
  border-right: 1px solid #646464;
  margin-right: 22px;
  padding-right: 22px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__headline {
  max-width: 340px;
  margin: 0 auto;
  font-family: arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__login {
  margin-top: 20px;
  padding-right: 30px;
  font-family: arial, sans-serif;
  font-size: 13px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__login a.subscription__login-link {
  color: red;
  font-weight: 700;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__login a.subscription__login-link:active {
  color: red;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__login .subscription__login-icon {
  margin-left: 5px;
  position: absolute;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor {
  color: #fff;
  background-color: red;
  border-radius: 2px;
  width: 154px;
  margin-top: 20px;
  padding: 12px 6px 11px 7px;
  font-family: Open Sans, arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
  text-decoration: none;
  display: inline-block;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened {
  box-sizing: border-box;
  height: 100%;
  padding-top: 150px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__logo {
  margin-top: 0;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__logo svg {
  width: 190px;
  height: 110px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__login {
  margin-left: -20px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__login .subscription__login-link {
  color: red;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__login span.subscription__login-icon svg {
  margin-top: -3px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened a.subscription__type-anchor {
  margin-top: 34px;
}

div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened h2.subscription__headline {
  font-family: Open Sans, arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
}

div.gallery-main-container div.gallery a.btn {
  top: 0;
}

div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock {
  padding: 35px 0;
}

div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock h2.subscription__headline, div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__login, div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor, div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.gallery__lock-bpz {
  font-family: Open Sans, arial, sans-serif;
}

div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__logo svg {
  width: 200px;
  height: 50px;
}

div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__logos {
  margin-top: 10px;
}

div.gallery__bpz div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__login span.subscription__login-icon svg {
  margin-top: 0;
}

div.gallery-main__horoscopes div.gallery-main-container div.gallery__subscription-lock #gallery-subscription-lock h2.subscription__headline, div.gallery-main__horoscopes div.gallery-main-container div.gallery__subscription-lock #gallery-subscription-lock div.subscription__login, div.gallery-main__horoscopes div.gallery-main-container div.gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor {
  font-family: roboto, arial, sans-serif;
}

#article .gallery__horoskopy .jcarousel {
  padding-bottom: 0;
}

div.gallery__zpravy div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__headline {
  font-family: Open Sans, arial, sans-serif;
}

div.gallery__zpravy div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__login span.subscription__login-icon svg {
  margin-top: -4px;
}

div.gallery__zpravy div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor {
  font-family: Open Sans, arial, sans-serif;
}

@media only screen and (max-width: 1023px) {
  div.gallery-main-container div.gallery .gallery-main-container .gallery .btn {
    top: 0;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__logo svg {
    width: 99px;
    height: 57px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__headline {
    max-width: 66%;
    margin: 0 auto;
    font-size: 15px;
    line-height: 19px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock .subscription__login {
    margin-top: 11px;
    padding-right: 23px;
    font-size: 10px;
    line-height: 12px;
    position: relative;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock div.subscription__login-icon svg {
    margin-top: -3px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock a.subscription__type-anchor {
    width: 132px;
    margin-top: 17px;
    padding: 10px 0;
    font-size: 9px;
    line-height: 10px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 23px;
    display: flex;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__logo svg {
    width: 99px;
    height: 57px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened div.subscription__login {
    margin-top: 23px;
    font-size: 10px;
    line-height: 12px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened a.subscription__type-anchor {
    width: 132px;
    margin-top: 23px;
    padding: 10px 0;
    font-family: arial, sans-serif;
    font-size: 9px;
    line-height: 10px;
  }

  div.gallery-main-container div.gallery .gallery__subscription-lock #gallery-subscription-lock.gallery--opened h2.subscription__headline {
    font-family: arial, sans-serif;
    font-size: 15px;
    line-height: 19px;
  }

  .subscription__login-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  .subscription__login-icon svg {
    width: 18px;
    height: 18px;
  }
}

div.gallery-main__bpz {
  font-family: Open Sans, arial, sans-serif;
}

div.gallery-main__bpz div.gallery-main-container div.gallery .btn {
  top: 0;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock div.subscription__logo {
  margin-top: 25px;
  margin-bottom: 10px;
  font-family: Open Sans, arial, sans-serif;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__headline {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__headline, div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__login {
  font-family: Open Sans, arial, sans-serif;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__login {
  margin-top: 15px;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__login-icon svg {
  margin-top: -3px;
  font-family: Open Sans, arial, sans-serif;
}

div.gallery-main__bpz div.gallery-main-container div.gallery div.gallery__subscription-lock div#gallery-subscription-lock .subscription__type-anchor {
  margin-top: 20px;
  font-family: Open Sans, arial, sans-serif;
}

.subscription-gal-strip-lock {
  z-index: 0;
  width: 160px;
  height: 90px;
  display: flex;
  position: absolute;
  left: 10px;
}

.subscription-gallery__logo {
  z-index: 2;
  position: absolute;
}

.subscription-gal-strip-lock__shadow {
  opacity: 1;
  z-index: 1;
  background: #222b38;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

body .gallery-strip__horoscopes .subscription-gal-strip-lock {
  z-index: 1;
  width: 100%;
  height: 100%;
}

body .gallery-strip__horoscopes .subscription-gallery__logo {
  z-index: 2;
  position: absolute;
}

body .gallery-strip__horoscopes .subscription-gallery__logo svg {
  margin: 20px 0 0 6px;
  width: 97px !important;
  height: 21px !important;
}

body .gallery-strip__bpz .gallery-strip a.image {
  position: relative;
}

body .gallery-strip__bpz .gallery-strip a.image .subscription-gal-strip-lock {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

body .gallery-strip__bpz .gallery-strip a.image .subscription-gallery__logo svg {
  margin: 20px 0 0 6px;
  width: 97px !important;
  height: 21px !important;
}

div.article-gallery-zpravy .subscription-gal-strip-lock {
  z-index: 1;
  width: 150px;
  height: 84px;
  display: block;
  position: absolute;
  left: 0;
}

div.article-gallery-zpravy .subscription-gal-strip-lock .subscription-gallery__logo {
  z-index: 2;
  position: absolute;
}

div.article-gallery-zpravy .subscription-gal-strip-lock .subscription-gallery__logo svg {
  margin: 32px 26px;
}

div.article-gallery-zpravy .subscription-gal-strip-lock .subscription-gal-strip-lock__shadow {
  z-index: 1;
  background: #16212d;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

div.article-gallery-zpravy .subscription-gal-strip-lock .subscription-gal-strip-lock__dummy-img {
  min-width: 100px;
}

.gallery-strip a {
  position: relative;
}

.subscription-gal-strip-lock {
  width: 110px;
  height: 100%;
  max-height: 62px;
  left: 0;
}

.subscription-gallery__logo {
  top: 23px;
  left: 14px;
}

.subscription-gallery__logo svg {
  margin: 0;
  width: 83px !important;
  height: 15px !important;
}

@media screen and (max-width: 960px) {
  body div.gallery-strip__bpz a.iamage {
    position: relative;
  }

  body div.gallery-strip__bpz .subscription-gal-strip-lock__dummy {
    width: 98px;
  }

  body div.gallery-strip__bpz .subscription-gal-strip-lock {
    z-index: 1;
    width: 100%;
    height: 100%;
    max-height: 70px;
    left: 0;
  }

  body div.gallery-strip__bpz .subscription-gallery__logo {
    margin: 4px -7px;
  }

  body div.gallery-strip__bpz .subscription-gallery__logo svg {
    width: 80px;
  }

  .gallery-strip__horoscopes .subscription-gallery__logo {
    width: 97px;
    height: 21px;
    margin: 20px 0 0 6px;
    top: .5px;
    left: 1px;
  }
}

.subscription-faq {
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  max-width: 100%;
  margin: 20px auto;
  padding: 15px;
}

.subscription-faq__headline {
  margin: 10px 0 20px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.subscription-faq__questions {
  padding: 0;
}

.subscription-faq__answer {
  margin-top: 0;
  display: none;
}

.subscription-faq__answer p {
  margin: 15px 0 0;
  font-size: 13px;
}

.subscription-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.subscription-faq__question, .subscription-types-switcher {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  font-size: 13px;
  line-height: 20px;
  list-style-type: none;
  position: relative;
}

.subscription-faq__question:after, .subscription-types-switcher:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.subscription-faq__question:hover, .subscription-types-switcher:hover {
  cursor: pointer;
}

.subscription-faq__question.active .subscription-faq__answer, .subscription-types-switcher.active .subscription-faq__answer {
  display: block;
}

.subscription-faq__question.active:after, .subscription-types-switcher.active:after {
  transform: rotate(0);
}

.subscription-types-switcher {
  border-bottom: 0;
  font-size: 13px;
  display: inline-block;
}

.subscription-types-switcher:after {
  filter: invert(15%) sepia(82%) saturate(5998%) hue-rotate(358deg) brightness(105%) contrast(115%);
}

.subscription-types-switcher__holder {
  text-align: center;
  margin-top: 5px;
}

.subscription-types-switcher__holder ~ div, .subscription-types-switcher__holder ~ ul {
  display: none;
}

.subscription-types-switcher__holder.active ~ div, .subscription-types-switcher__holder.active ~ ul {
  display: block;
}

.subscription-types-switcher__holder strong:before {
  content: "Zobrazit";
}

.subscription-types-switcher__holder.active strong:before {
  content: "Skrýt";
}

.subscription-types-switcher__holder.active .subscription-types-switcher:after {
  transform: rotate(0);
}

.subscription-types-switcher .subscription__login-icon {
  vertical-align: bottom;
}

@media only screen and (max-width: 480px) {
  .subscription-faq__contact a {
    text-decoration: underline;
  }

  .subscription-faq__contact a:hover, .subscription-faq__contact a:active {
    text-decoration: none;
  }
}

.subscription-bpz .subscription-faq {
  background: #f8f8f8;
}

#article .articlePaid .article-body .subscription-centered {
  width: 640px;
  margin: 0 0 0 -100px;
}

#article .articlePaid .article-body .subscription-centered .subscription--new h3.subscription__payment-options-headline {
  margin: 4px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px !important;
}

.body-wrapper--white .subscription--article.fade-out:before {
  background: linear-gradient(rgba(255, 255, 255, 0) 0% 2%, #fff 70% 100%);
}

.artPaid, .list-article .artPaid {
  z-index: 800;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  width: 61px;
  height: 13px;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.artPaid--static, .list-article .artPaid--static {
  position: static;
}

.artPaid--subscription, .list-article .artPaid--subscription {
  letter-spacing: -.6px;
  width: 150px;
  height: 46px;
  margin: 0 auto;
  position: static;
}

.artPaid--gallery-lock, .list-article .artPaid--gallery-lock {
  margin: auto;
  position: static;
}

.artPaid--article-tall, .list-article .artPaid--article-tall {
  left: 15%;
}

.artPaid.artPaid__inContent, .list-article .artPaid.artPaid__inContent {
  vertical-align: top;
  margin-right: 5px;
  position: static;
}

.artPaid--article-main-image, .list-article .artPaid--article-main-image {
  width: 70px;
  height: 15px;
  top: 20px;
  left: 20px;
}

.artPaid .relative {
  margin-bottom: 5px;
  position: relative;
}

.artPaid.articleMainArtobj {
  top: 10px;
}

.internal-promo {
  border-top: 3px solid red;
  margin: 35px 10px;
  font-weight: bold;
}

.internal-promo h2 {
  text-transform: uppercase;
  color: red;
  margin: 5px 0 10px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 18px;
}

.internal-promo__items:after {
  content: "";
  clear: both;
  width: 100%;
  height: 1px;
  display: block;
}

.internal-promo-items__item {
  float: left;
  width: calc(25% - 15px);
  margin-bottom: 30px;
  margin-right: 20px;
}

.internal-promo-items__item:nth-child(4n+1) {
  clear: both;
}

.internal-promo-items__item:nth-child(4n+4) {
  margin-right: 0;
}

.internal-promo-item__image {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 82px;
  margin-bottom: 10px;
  display: flex;
}

.internal-promo-item__image .loading-placeholder {
  justify-content: center;
  align-items: center;
  display: flex;
}

.internal-promo-item__image .loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.internal-promo-item__image .loading-placeholder.item-loaded img {
  object-fit: contain;
  max-width: 140px;
  max-height: 82px;
}

.internal-promo-item__title, .internal-promo-item__more {
  font-family: arial, Helvetica, sans-serif;
  font-size: 15px;
  display: block;
}

.internal-promo-item__title {
  color: #16212d;
  height: 45px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.internal-promo-item__title:hover {
  text-decoration: none;
}

.internal-promo-item__more {
  color: red;
  text-transform: lowercase;
  border-bottom: 2px solid red;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.internal-promo-item__more:hover {
  color: #5c6773;
  border-bottom: 0;
  text-decoration: none;
  transition: all .3s linear;
}

.internal-promo-item__more:before, .internal-promo-item__more:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: red;
  width: 2px;
  height: 5px;
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  transform: skewX(40deg);
}

.internal-promo-item__more:after {
  transform-origin: 100% 0;
  top: 10px;
  transform: skewX(-40deg);
}

.internal-promo-item__more:hover:before, .internal-promo-item__more:hover:after {
  background-color: #5c6773;
  transition: all .3s linear;
}

@media only screen and (max-width: 699px) {
  .internal-promo-items__item {
    width: calc(50% - 6px);
    margin-right: 6px;
  }

  .internal-promo-items__item:nth-child(2n+2) {
    margin-right: 0;
  }

  .internal-promo-item__image {
    background: #b6c1cc;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .internal-promo {
    margin: 35px 0;
  }
}

